import React from "react";
import { graphql } from "gatsby"
import SEO from "../components/seo"


const Contact = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <div className="pageWrapper page">
      <SEO
        title="Terms of Service"
        description="Terms of Service for use at Dinchat"
        pagePath="/terms"
      />

      <h1>Terms of Service</h1>
      <div className="dividerHr">
        <span className="dividerSpan"></span>
      </div>

      <div className="termsPage">
        <h2> Välkommen till dinanyheter.se </h2>
        <p> Dessa villkor beskriver regler för användning av dinanyheter.se's hemsida och alla dess underdomäner. </p> <br />
        <p> Genom att använda denna webbplats antar vi att du accepterar dessa villkor helt och hållet. Fortsätt inte använda dinanyheter.se's webbplats
          om du inte accepterar alla villkoren som anges på den här sidan. </p>
        <p> Följande terminologi gäller för dessa användarvillkor, sekretesspolicy och ansvarsfriskrivning
          och alla eller alla avtal: "Klient", "Du" och "Din" hänvisar till dig, personen som åtkomst till denna webbplats
          och accepterar Bolagets villkor. "Company", "Ourselves", "We", "Our" och "Us" hänvisar
          till vårt företag. "Party", "Parties" eller "Us" avser både kunden och oss själva, eller antingen kunden
          eller oss själva. Alla villkor avser erbjudandet, acceptansen och betalningen av betalningen som krävs för att genomföra
          Processen av vår hjälp till kunden på det mest lämpliga sättet, oavsett om det sker formella möten
          med en bestämd varaktighet, eller på något annat sätt, för det uttryckliga syftet att uppfylla kundens behov i fråga
          av tillhandahållande av Bolagets angivna tjänster / produkter, i enlighet med och med förbehåll för gällande lag
          av. Varje användning av ovanstående terminologi eller andra ord i singular, flertalet,
          kapitalisering och / eller han / hon eller de, tas som utbytbara och därför hänvisar till samma. </p> <h2> Cookies </h2>
        <p> Vi använder cookies. Genom att använda dinanyheter.se's webbplats godkänner du användningen av cookies
          i enlighet med dinanyheter.se's integritetspolicy. </p> <p> De flesta av dagens interaktiva webbplatser
            använd cookies för att vi ska kunna hämta användaruppgifter för varje besök. Cookies används i vissa delar av vår webbplats
            för att möjliggöra funktionaliteten i detta område och användarvänlighet för de personer som besöker. Några av våra
            partner / annonspartner kan också använda cookies. </p>
        <h2> Regler </h2>
        <p>
          Du måste vara minst 18 år för att kunna använda denna webbplats. Vi tar inget ansvar för vad
          du skickar in, laddar upp eller skriver till den här webbplatsen. Du, användaren, har ansvaret för att se till vad
          du skickar är lagligt enligt både din lokala lag och svensk lag.
        </p>
        <p>
          Den här webbplatsen riktar sig till svenska användare och genom att komma till denna webbplats accepterar du alla regler.
        </p>
        <h2> Licens </h2>
        <p> Om inte annat anges, äger dinanyheter.se och / eller licensierarna immateriella rättigheter för
          allt material på dinanyheter.se. Alla immateriella rättigheter är reserverade. Du kan se och / eller skriva ut
          sidor från https://dinanyheter.se för eget bruk, med förbehåll för restriktioner som anges i dessa användarvillkor. </p>
        <p> Du får inte: </p>
        <ol>
          <li> Publicera material från https://dinanyheter.se </li>
          <li> Sälj, hyra eller dellicensmaterial från https://dinanyheter.se </li>
          <li> Kopiera, kopiera eller kopiera material från https://dinanyheter.se </li>
        </ol>
        <p> Omfördela innehållet från dinanyheter.se (om inte innehåll särskilt är gjord för omfördelning). </p>
        <h2> Inlägg av användare </h2>
        <ol>
          <li> Denna överenskommelse ska börja på dagen för detta. </li>
          <li> Vissa delar av den här webbplatsen ger användarna möjlighet att posta och utbyta åsikter, information,
            material och data ("Inlägg") i områden på webbplatsen. dinanyheter.se skärmar inte, redigerar, publicerar
            eller granskar Inlägg innan de visas på webbplatsen och Inlägg speglar inte synpunkterna eller
            åsikter av dinanyheter.se, dess agenter eller dotterbolag. Inlägg återspeglar den syn på och uppfattning av
            den person som postar sådan syn eller åsikt. I den utsträckning som tillåts enligt gällande lagar skall dinanyheter.se
            inte hållas ansvarig för Inlägg eller för eventuella förlustkostnader, ansvar, skadestånd eller kostnader som orsakats
            och eller led av användningen av och / eller posten av och / eller utseendet på Inlägg på detta
            webbplats. </li>
          <li> dinanyheter.se Förbehåller sig rätten att övervaka all aktivitet och alla inlägg samt att ta bort
            eventuella inlägg och användare som den anser
            enligt eget gottfinnande vara olämpligt, stötande eller på annat sätt bryter mot dessa villkor. </li>
          <li> Du garanterar och representerar att:
            <ol>
              <li> Du har rätt att skicka Inlägg på vår hemsida och medger att du har alla nödvändiga licenser och samtycken till
                göra det; </li>
              <li> Inläggen strider inte mot någon immateriell rättighet, inklusive, utan begränsning, upphovsrätt,
                patent eller varumärke eller annan proprietär rättighet för någon tredje part, </li>
              <li> Inläggen innehåller inga förolämpande, kränkande, stötande, oanständigt eller annat olagligt material
                eller material som är en invasion av privatlivet </li>
              <li> Inläggen kommer inte att användas för att begära eller marknadsföra affärer eller anpassade eller presenterade kommersiella aktiviteter
                eller olaglig aktivitet. </li>
            </ol>
          </li>
          <li> Härmed ger du <strong> dinanyheter.se </strong> en icke-exklusiv royaltyfri licens för att använda, reproducera,
            redigera och auktorisera andra att använda, reproducera och redigera någon av dina inlägg i alla former, format
            eller media. </li>
        </ol>
        <h2> Hyperlänk till vårt innehåll </h2>
        <ol>
          <li> Följande organisationer kan länka till vår webbplats utan föregående skriftligt godkännande:
            <ol>
              <li> Offentliga byråer </li>
              <li> Sökmotorer; </li>
              <li> Nyhetsorganisationer; </li>
              <li> Online katalogdistributörer när de listar oss i katalogen kan länka till vår webbplats i samma
                sätt som de hyperlänkar till webbplatser för andra börsnoterade företag; och </li>
              <li> Systemwide Accredited Business, utom att söka ideella organisationer, välgörenhet köpcentrum,
                och välgörenhetsinsamlingsgrupper som inte kan länka till vår webbplats. </li>
            </ol>
          </li>
        </ol>
        <ol start="2">
          <li> Dessa organisationer kan länka till vår hemsida, till publikationer eller till annan webbplatsinformation så länge
            som länken: (a) är inte på något sätt vilseledande (b) innebär inte felaktigt sponsring, godkännande eller
            godkännande av länkpartiet och dess produkter eller tjänster och (c) passar inom ramen för länkningen
            partys webbplats.
          </li>
          <li> Vi kan överväga och godkänna efter eget gottfinnande andra länkförfrågningar från följande typer av organisationer:
            <ol>
              <li> Allmänt kända konsument- och / eller affärsinformationskällor som handelskammare, amerikanska
                Automobile Association, AARP och Consumer Union; </li>
              <li> communitywebbplatser i dot.com; </li>
              <li> föreningar eller andra grupper som representerar välgörenhetsorganisationer, inklusive välgörenhetsgivande webbplatser, </li>
              <li> Distributörer av webbkataloger </li>
              <li> Internetportaler; </li>
              <li> bokföring, lag och konsultföretag vars primära kunder är företag och </li>
              <li> utbildningsinstitutioner och branschorganisationer. </li>
            </ol>
          </li>
        </ol>
        <p> Vi godkänner länkförfrågningar från dessa organisationer om vi bestämmer att: (a) länken inte skulle återspegla
          ogynnsamt hos oss eller våra ackrediterade företag (till exempel branschorganisationer eller andra organisationer
          som representerar inneboende misstänkta typer av verksamhet, till exempel arbetskraftsmöjligheter, ska inte tillåtas
          att länka); (b) Organisationen har inte en otillfredsställande rekord hos oss (c) Fördelen för oss från
          synligheten i samband med hyperlänken uppväger avsaknaden av Dinchat och (d) där
          länk är i samband med allmän resursinformation eller är i övrigt förenlig med redaktionellt innehåll
          i ett nyhetsbrev eller liknande produkt som främjar organisationens uppdrag. </p>

        <p> Dessa organisationer kan länka till vår hemsida, till publikationer eller till annan webbplatsinformation så länge som
          länken: (a) är inte på något sätt vilseledande (b) innebär inte felaktigt sponsring, godkännande eller godkännande
          av den kopplande parten och det produkter eller tjänster; och (c) passar inom ramen för den länkande partens
          webbplats. </p>

        <p> Om du är en av de organisationer som anges i punkt 2 ovan och är intresserade av att länka till vår hemsida,
          Du måste meddela oss genom att skicka ett e-postmeddelande till <a href="mailto:support@dinanyheter.se" title="sänd ett mail till support@dinanyheter.se"> support@dinanyheter.se </a>.
          Var vänlig ange ditt namn, ditt organisationsnamn, kontaktuppgifter (t.ex. telefonnummer och / eller e-post
          adress) samt webbadressen till din webbplats, en lista över alla webbadresser som du avser att länka till vår webbplats,
          och en lista över URL: erna på vår webbplats som du vill länka till. Tillåt 2-3 veckor för ett svar. </p>

        <p> Godkända organisationer kan hyperlänk till vår webbplats enligt följande: </p>

        <ol>
          <li> Med hjälp av vårt företagsnamn; eller </li>
          <li> Genom att använda den enhetliga resurslocatorn (webbadressen) som är länkad till; eller </li>
          <li> Med hjälp av någon annan beskrivning av vår webbplats eller material som är kopplat till det är meningsfullt inom
            sammanhang och format för innehåll på länkpartiets webbplats. </li>
        </ol>
        <p> Ingen användning av dinanyheter.se's logotyp eller annat konstverk kommer att tillåtas för att länka frånvarande en varumärkeslicens
          avtal. </p>
        <h2> Iframes </h2>
        <p> Utan föregående godkännande och uttryckligt skriftligt tillstånd får du inte skapa ramar runt våra webbsidor eller
          använd andra tekniker som på något sätt förändrar visuell presentation eller utseende på vår webbplats. </p>
        <h2> Förbehåll av rättigheter </h2>
        <p> Vi förbehåller oss rätten när som helst och efter eget gottfinnande att begära att du tar bort alla länkar eller något särskilt
          länka till vår webbplats. Du accepterar att omedelbart ta bort alla länkar till vår webbplats efter en sådan begäran. Vi också
          förbehåller sig rätten att när som helst ändra dessa villkor och dess kopplingspolicy. Genom att fortsätta
          att länka till vår webbplats, accepterar du att vara bunden till och följa dessa länkvillkor. </p>
        <h2> Ta bort länkar från vår webbplats </h2>
        <p> Om du hittar någon länk på vår hemsida eller någon länkad webbplats som kan bestridas av någon anledning kan du kontakta
          oss om detta. Vi kommer att överväga förfrågningar om att ta bort länkar men har ingen skyldighet att göra det eller att svara
          direkt till dig. </p>
        <p> Samtidigt som vi strävar efter att se till att informationen på denna webbplats är korrekt, garanterar vi inte fullständigheten
          eller noggrannhet; Vi förbinder oss inte heller att se till att webbplatsen är tillgänglig eller att materialet på
          webbplatsen hålls uppdaterad. </p>
        <h2> Innehållsansvar </h2>
        <p> Vi har inget ansvar eller ansvar för innehåll som visas på din webbplats. Du godkänner att ersätta
          och försvara oss mot alla krav som uppstår på grund av eller baserat på din webbplats. Inga länk (er) kan förekomma på någon
          sida på din webbplats eller i något sammanhang som innehåller innehåll eller material som kan tolkas som
          kränkande, obscen eller brottslig, eller som kränker, annars bryter mot eller förespråkar överträdelsen eller
          annan kränkning av tredje parts rättigheter. </p>
        <h2> Varning </h2>
        <p> I den utsträckning som tillåts enligt gällande lag utesluter vi alla föreställningar, garantier och villkor som gäller vår hemsida och användningen av denna webbplats (inklusive, utan begränsning, några garantier som följer av lag med avseende på tillfredsställande kvalitet, lämplighet för ändamål och / eller användningen av rimlig vård och skicklighet). Ingenting i denna ansvarsfriskrivning kommer att: </p>
        <ol>
          <li> begränsa eller utesluta vår eller din skyldighet för dödsfall eller personskada som härrör från vårdslöshet, </li>
          <li> Begränsa eller utesluta vårt eller ditt ansvar för bedrägerier eller bedräglig förvrängning. </li>
          <li> Begränsa några av våra eller dina skulder på något sätt som inte är tillåtet enligt gällande lag eller </li>
          <li> utesluta några av våra eller dina skulder som inte kan uteslutas enligt gällande lag. </li>
        </ol>
        <p> Ansvarsbegränsningar och uteslutningar som anges i detta avsnitt och på annat håll i denna ansvarsfriskrivning: (a)
          omfattas av föregående stycke och (b) reglera alla skulder som uppkommer enligt ansvarsfriskrivningen eller
          i förhållande till föremålet för denna ansvarsfriskrivning, inklusive skulder som uppkommer i kontrakt, i skadestånd
          (inklusive försummelse) och för brott mot lagstadgad tull. </p>
        <p> I den utsträckning som webbplatsen och informationen och tjänsterna på webbplatsen tillhandahålls kostnadsfritt,
          Vi kommer inte att vara ansvariga för förlust eller skada av någon art. </p>

      </div>
    </div>
  )
}

export default Contact

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`